import { arrayRemove, arrayUnion, collection, doc, DocumentData, getDoc, getDocs, increment, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { intitalizeFireBase } from "../firebase/clientApp";
import { firestore } from "../firebase/config";


export const fetchProducts = async () => {
    //
    const products = firestore.collection('products').get();
    let productsArr: FirebaseFirestore.DocumentData[] = [];
    let data = await products;
    data.docs.forEach(
        (res: any) => {
            productsArr.push(res.data())
        }
    );
    const convertedJsonArray = productsArr.map(obj => {
        const convertedObj = { ...obj }; // Copy the original object

        Object.keys(obj).forEach(key => {
            const match = key.match(/^(.+)(Ar|En)$/); // Match the property name and language suffix
            if (match) {
                const [, propertyName, language] = match; // Extract property name and language
                if (!convertedObj[propertyName]) {
                    convertedObj[propertyName] = {}; // Initialize property if not exists
                }
                convertedObj[propertyName][language.toLowerCase()] = obj[key]; // Assign value to the property
                delete convertedObj[key]; // Remove the original language-specific property
            }
        });

        return convertedObj;
    });

    console.log(convertedJsonArray);

    // const response = await fetch('https://square-one-one.vercel.app/assets/data/products.json');
    // const data = await response.json();
    return convertedJsonArray;
}

export const fetchProjects = async () => {
    const projects = firestore.collection('projects').get();
    let projectsArr: FirebaseFirestore.DocumentData[] = [];
    let data = await projects;
    data.docs.forEach(
        (res: any) => {
            projectsArr.push(res.data())
        }
    );
    const convertedJsonArray = projectsArr.map(obj => {
        const convertedObj = { ...obj }; // Copy the original object

        Object.keys(obj).forEach(key => {
            const match = key.match(/^(.+)(Ar|En)$/); // Match the property name and language suffix
            if (match) {
                const [, propertyName, language] = match; // Extract property name and language
                if (!convertedObj[propertyName]) {
                    convertedObj[propertyName] = {}; // Initialize property if not exists
                }
                convertedObj[propertyName][language.toLowerCase()] = obj[key]; // Assign value to the property
                delete convertedObj[key]; // Remove the original language-specific property
            }
        });

        return convertedObj;
    });

    console.log(convertedJsonArray);
    return convertedJsonArray;
}

export const fetchBlogs = async () => {
    const blogs = firestore.collection('blogs').get();
    let blogsArr: FirebaseFirestore.DocumentData[] = [];
    let data = await blogs;
    data.docs.forEach(
        (res: any) => {
            blogsArr.push(res.data())
        }
    );
    const convertedJsonArray = blogsArr.map(obj => {
        const convertedObj = { ...obj }; // Copy the original object

        Object.keys(obj).forEach(key => {
            const match = key.match(/^(.+)(Ar|En)$/); // Match the property name and language suffix
            if (match) {
                const [, propertyName, language] = match; // Extract property name and language
                if (!convertedObj[propertyName]) {
                    convertedObj[propertyName] = {}; // Initialize property if not exists
                }
                convertedObj[propertyName][language.toLowerCase()] = obj[key]; // Assign value to the property
                delete convertedObj[key]; // Remove the original language-specific property
            }
        });

        return convertedObj;
    });

    return convertedJsonArray;
}


export const fetchClientPartners = async () => {
    const blogs = firestore.collection('clientpartners').get();
    let blogsArr: FirebaseFirestore.DocumentData[] = [];
    let data = await blogs;
    data.docs.forEach(
        (res: any) => {
            blogsArr.push(res.data())
        }
    );
    
    console.log("🚀 ~ fetchClientPartners ~ blogsArr:", blogsArr)

    return blogsArr;
}

