import React from 'react'
import BlogsList from '../components/BlogsList'
import WhatsApp from '../components/WhatsApp'
import heroImage from '../assets/products-bg.jpg';

const Blogs = () => {
    return (
        <div>
            <div className='relative h-52'>
                <img src={heroImage} className='absolute top-0 left-0 h-52 object-cover w-full' alt='' />
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
                <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
                    <div className='font-bold text-3xl'>Blogs</div>
                </div>
            </div>
            <div className='py-6 px-4 sm:px-8 md:px-10 lg:px-24'>
                <BlogsList />
                <WhatsApp />
            </div>
        </div>
    )
}

export default Blogs