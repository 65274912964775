import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import heroImage from '../assets/products-bg.jpg';
import WhatsApp from '../components/WhatsApp';
import { AiOutlineClose } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import { fetchProjects } from '../services/SQAPI';
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../services/language.context';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxWidth: '80%',
        maxHeight: '80%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');
const Gallery = () => {
    let subtitle: any;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [imageArr, setImageArr] = useState<any>({ projectName: { 'en': '', 'ar': '' }, images: [] });
    const [projects, setProjects] = useState<any>([]);
    const { t } = useTranslation();
    const { currentLanguage } = useLanguageContext();

    useEffect(() => {
        getProjects();
    }, [])

    const getProjects = async () => {
        const data = await fetchProjects();
        console.log("🚀 ~ file: ProjectsList.tsx:16 ~ getProducts ~ data:", data)
        setProjects(data);
    }

    function openModal(val: any) {
        console.log("🚀 ~ file: Gallery.tsx:41 ~ openModal ~ val:", val)
        setIsOpen(true);
        setImageArr(val);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div>
            <div className='relative h-52'>
                <img src={heroImage} className='absolute top-0 left-0 h-52 object-cover w-full' alt='' />
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
                <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
                    <div className='font-bold text-3xl'>{t('gallery')}</div>
                </div>
            </div>
            <div className='py-6 px-4 sm:px-8 md:px-10 lg:px-24'>
                <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
                    {projects.map((val: any) => (
                        <div className='cursor-pointer rounded-md overflow-hidden relative h-72 group shadow-md' onClick={() => openModal(val)}>
                            <img src={val.images[0]} className='w-full object-cover h-full' alt={val.projectName.en} />
                            <div className='absolute bottom-0 left-0 rounded-sm py-2 px-3 w-full'>
                                <div className='bg-slate-100 opacity-70 absolute top-0 left-0 h-full w-full group-hover:bg-primary group-hover:opacity-100 transition-all duration-400 ease-in-out'></div>
                                <div className='relative font-medium text-base text-black group-hover:text-white transition-all duration-400 ease-in-out'>{val.projectName[currentLanguage]}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='flex justify-between'>
                    <div className='font-semibold'>{imageArr.projectName[currentLanguage]}</div>
                    <IoClose size={20} color='red' className='cursor-pointer' onClick={closeModal} />
                </div>
                <div style={{ direction: 'initial' }}>
                    <Carousel className='mt-2' showArrows autoPlay infiniteLoop showThumbs stopOnHover showIndicators showStatus={false}>
                        {imageArr?.images.map((val: any) => (
                            <div className="h-full">
                                <img src={val} alt="" className='w-full object-cover h-full' />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </Modal>
            <WhatsApp />
        </div>
    )
}

export default Gallery