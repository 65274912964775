import React, { useEffect, useState } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { useLocation } from 'react-router-dom';
import WhatsApp from '../components/WhatsApp';
import { fetchProducts } from '../services/SQAPI';
import { useLanguageContext } from '../services/language.context';
import { useTranslation } from 'react-i18next';
// import { ProductsArray } from '../constants/data';

const ProductDetails = () => {
    let location = useLocation();
    const [value, setValue] = useState<any>();
    const { currentLanguage } = useLanguageContext();
    const { t } = useTranslation();

    useEffect(() => {
        getProducts();
    }, [location.pathname]);

    const getProducts = async () => {
        const data = await fetchProducts();
        console.log("🚀 ~ getProducts ~ data:", data)
        const title = location.pathname.split('/')[3].replaceAll('-', ' ');
        const filteredVal = data.filter((val: any) => val?.productName?.en === title);
        setValue(filteredVal[0]);
    }

    return (
        <div>
            <div className='relative h-60'>
                <video autoPlay loop muted playsInline className='absolute top-0 left-0 h-60 object-cover w-full'>
                    <source src="https://www.isotrack.eu/wp-content/uploads/2022/10/naslovniva_spletna-stran.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {/* <img src={heroImage} className='absolute top-0 left-0 h-52 object-cover w-full' alt='' /> */}
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
                <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
                    <div className='font-bold text-3xl'>{t('products')}</div>
                </div>
            </div>
            <div className='py-6 px-4 sm:px-8 md:px-10 lg:px-24'>
                <div className="grid grid-cols-1 md:grid-cols-3 justify-between gap-5 my-6">
                    <div className='col-span-2'>
                        <div className="text-3xl font-bold mb-4">{value?.productName[currentLanguage]}</div>
                        <p className='leading-6'>
                            {value?.description[currentLanguage]}
                        </p>
                    </div>
                    <div className='justify-self-end hidden md:block'>
                        <img src={value?.thumbnail[0]} alt="" />
                    </div>
                </div>
                {/* <video autoPlay={false} loop muted playsInline>
                    <source src="https://www.isotrack.eu/wp-content/uploads/2022/10/naslovniva_spletna-stran.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
                <div className="text-lg py-4 font-semibold">{t('gallery')}</div>
                <Gallery>
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5'>
                        {value?.images.map((val: any) => (
                            <Item
                                cropped
                                original={val}
                                thumbnail={val}
                                width="1920"
                                height="2879"
                            >
                                {({ ref, open }) => (
                                    <img
                                        className='w-full object-cover h-60'
                                        src={val}
                                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                                        onClick={open}
                                        alt=""
                                    />
                                )}
                            </Item>
                        ))}

                    </div>
                </Gallery>
            </div>

            <WhatsApp />
        </div>
    )
}

export default ProductDetails