import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { ClientList, PartnersList } from '../constants/data';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchClientPartners } from '../services/SQAPI';

const PartnersHome = () => {
    const { t } = useTranslation();
    const [clients, setClients] = useState<any>([]);

    useEffect(() => {
        getClientPartners();
    }, [])

    const getClientPartners = async () => {
        const data = await fetchClientPartners();
        console.log("🚀 ~ file: ProjectsList.tsx:16 ~ getProducts ~ data:", data)
        setClients(data);
    }

    return (
        <div>
            <div className="bg-white">
                <div className='py-16'>
                    <div className="flex justify-center">
                        <span className="relative uppercase font-semibold text-xl text-primary">{t('home_clients_title')}</span>
                    </div>
                    <Slider dots infinite swipe slidesToShow={4} slidesToScroll={1} autoplay speed={2000} autoplaySpeed={2000} pauseOnHover
                        responsive={[
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                        className='pt-6'
                    >
                        {clients.map((i: any) => {

                            return (
                                i.type == 'client' &&
                                <Link to={i.url} target='_blank' className="flex justify-center items-start w-[20rem]">
                                    <img src={i?.thumbnail[0]} alt='' />
                                </Link>
                            );
                        })}
                    </Slider>
                </div>
                <div className="bg-slate-100">
                    <div className='py-16'>
                        <div className="flex justify-center">
                            <span className="relative uppercase font-semibold text-xl text-primary">{t('home_partners_title')}</span>

                        </div>
                        <Slider dots infinite swipe slidesToShow={3} slidesToScroll={1} autoplay speed={2000} autoplaySpeed={2000} pauseOnHover
                            responsive={[
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1
                                    }
                                }
                            ]}
                            className='pt-6'
                        >
                            {clients.map((i:any) => {
                                return (
                                    i.type == 'partner' &&
                                    <Link to={i.url} target='_blank' className="px-10">
                                        <img src={i?.thumbnail[0]} alt='' />
                                    </Link>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default PartnersHome