import client1 from '../assets/client-1.png';
import client2 from '../assets/client-2.png';
import client3 from '../assets/client-3.png';
import client4 from '../assets/client-4.png';
import client5 from '../assets/client-5.png';

import partner1 from '../assets/partner-1.png';
import partner2 from '../assets/partner-2.png';
import partner3 from '../assets/partner-3.png';
import partner4 from '../assets/partner-4.png';

import value1 from '../assets/value1.png';
import value2 from '../assets/value2.png';
import value3 from '../assets/value3.png';
import value4 from '../assets/value4.png';
import value5 from '../assets/value5.png';

import blogPDF1 from '../assets/Blog1.pdf';
import blogPDF2 from '../assets/Blog2.pdf';


export const PartnersList = [
    { link: 'https://www.mobisportconcept.com/en/', logo: partner1 },
    { link: 'https://www.isotrack.eu/', logo: partner3 },
    { link: 'https://www.woodpad.cis2000srl.com/IT/', logo: partner4 },
];

export const ClientList = [
    { link: 'https://zatca.gov.sa/en/Pages/default.aspx', logo: client1 },
    { link: 'https://gowfc.com/', logo: client2 },
    { link: 'https://www.my.gov.sa/wps/portal/snp/agencies/agencyDetails/AC017/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zivQIsTAwdDQz9LQwNzQwCnS0tXPwMvYwNDAz0g1Pz9L30o_ArAppiVOTr7JuuH1WQWJKhm5mXlq8f4ehsYGiuX5DtHg4AwMrzkA!!/', logo: client3 },
    { link: 'https://www.aramco.com/', logo: client4 },
    { link: 'https://www.rcu.gov.sa/en/', logo: client5 }
];

export const Values = [
    {
        title: {
            en: 'Quality',
            ar: 'الجودة'
        },
        desc: {
            en: 'Pursuing excellence in every detail, ensuring the highest standards of craftsmanship, materials, and workmanship.',
            ar: 'الاهتمام بالجودة في كل التفاصيل يضمن اعلي معايير الصنعة ، المواد و المهنية.'
        },
        icon: value1,
    },
    {
        title: {
            en: 'Sustainability',
            ar: 'الاستدامة'
        },
        desc: {
            en: 'Integrating sustainable practices into our projects, minimizing environmental impact, and creating long-term value for our clients and communities.',
            ar: 'دمج الممارسات المستدامة في مشاريعنا، وتقليل التأثير البيئي، وخلق قيمة طويلة الأجل لعملائنا ومجتمعاتنا.'
        },
        icon: value2
    },
    {
        title: {
            en: 'Collaboration',
            ar: 'التعاون'
        },
        desc: {
            en: 'Embracing a collaborative approach, fostering open communication, and working closely with clients, partners, and stakeholders to achieve shared goals.',
            ar: 'اتباع طرق التعاون ، و سرعة الاتصال و العمل  عن قرب مع الزبائن  ، الشركاء و المساهمين لتحقيق الأهداف المشتركة.'
        },
        icon: value3,
    },
    {
        title: {
            en: 'Innovation',
            ar: 'الاختراع'
        },
        desc: {
            en: 'Embracing innovation and leveraging new technologies, materials, and methods to drive efficiency, enhance outcomes, and push the boundaries of what is possible.',
            ar: 'ادخال التقنيات و الابتكارات الجديدة ، المواد ، و الطرق للوصول الى الكفاءة ، تطوير النتائج ، و الوصول الى اقصى آفاق ممكنة.'
        },
        icon: value4,
    },
    {
        title: {
            en: 'Integrity',
            ar: 'الجودة'
        },
        desc: {
            en: 'Acting with integrity, honesty, and transparency, building trust with our clients, employees, and business partners through ethical conduct and responsible business practices.',
            ar: 'نحن نعمل بنزاهة ، شرف و شفافية ، لبناء الثقة مع عملائنا ، موظفينا و شركاء العمل  و  ذلك عبر تطبيق  السلوك الأخلاقي في العمل'
        },
        icon: value5,
    }
]
