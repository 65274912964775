import React from 'react';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer';
import Products from './pages/Products';
import Projects from './pages/Projects';
import Gallery from './pages/Gallery';
import AboutUs from './pages/AboutUs';
import Blogs from './pages/Blogs';
import ProductDetails from './pages/ProductDetails';
import ProjectDetails from './pages/ProjectDetails';
import BlogDetails from './pages/BlogDetails';
import Contact from './pages/Contact';
import { LanguageContextProvider } from './services/language.context';

function App() {
    return (
        <LanguageContextProvider>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products">
                    <Route index element={<Products />} />
                    <Route path='details/:id' element={<ProductDetails />} />
                </Route>
                <Route path="/projects">
                    <Route index element={<Projects />} />
                    <Route path='details/:id' element={<ProjectDetails />} />
                </Route>
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blogs"  >
                    <Route index element={<Blogs />} />
                    <Route path=":id" element={<BlogDetails />} />
                </Route>
            </Routes>
            <Footer />
        </LanguageContextProvider>
    );
}

export default App;
