import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import logo from '../assets/Logo.png';
import { FaPhone } from 'react-icons/fa';
import { FiMail, FiMap, FiMapPin, FiPhone } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="bg-gray-800 py-8">
            <div className="px-4 sm:px-8 md:px-10 lg:px-24 grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center items-start md:justify-items-stretch">
                <div className="flex items-center">
                    <img src={logo} alt="Company Name" width={50} className="" />
                    <div className='text-white text-lg mx-2'>
                        {t('company')}
                        <div className='text-base'>{t('company_desc')}</div>
                    </div>
                </div>
                <div>
                    <div className='text-center text-gray-400 mb-2'>{t('social_media')}</div>
                    <div className="flex justify-center">
                        <a href="/" className="text-gray-400 hover:text-gray-200 mx-2">
                            <FaFacebook className="h-6 w-6" />
                        </a>
                        <a href="/" className="text-gray-400 hover:text-gray-200 mx-2">
                            <FaTwitter className="h-6 w-6" />
                        </a>
                        <a href="/" className="text-gray-400 hover:text-gray-200 mx-2">
                            <FaInstagram className="h-6 w-6" />
                        </a>
                        <a href="https://www.linkedin.com/in/square-one-for-general-contracting-company-914524241/" target='_blank' rel="noreferrer" className="text-gray-400 hover:text-gray-200 mx-2">
                            <FaLinkedin className="h-6 w-6" />
                        </a>
                        <a href="https://youtube.com/@squareonesa" target='_blank' rel="noreferrer" className="text-gray-400 hover:text-gray-200 mx-2">
                            <FaYoutube className="h-6 w-6" />
                        </a>
                    </div>
                </div>
                <div className='flex gap-2 flex-col'>
                    <a href="tel:+966562222678" className='flex items-center justify-center md:justify-end'>
                        <FiPhone color='#cbd5e1' /> <div className='text-slate-300 px-2'>{t('phone_number')}</div>
                    </a>
                    <a href="mailto:info@sq1.sa" className='flex items-center justify-center md:justify-end'>
                        <FiMail color='#cbd5e1' /> <div className='text-slate-300 px-2'>info@sq1.sa</div>
                    </a>
                    <a href='https://goo.gl/maps/HS2GytZtCNV2QQxH8'
                        target='_blank'
                        rel="noreferrer"
                        className='flex items-start justify-center md:justify-end'>
                        <FiMapPin color='#cbd5e1' />
                        <div className='text-slate-300 px-2 text-center md:text-right '>
                            {t('address_line1')} <br />
                            {t('address_line2')} <br />
                            {t('address_line3')}
                        </div>
                    </a>
                </div>
            </div>
            <hr className="border-gray-700 my-6" />
            <p className="text-gray-400 text-sm text-center">&copy; 2023 SquareOne. All rights reserved.</p>
        </footer>
    );
}

export default Footer;
