import { HiOutlineDownload } from 'react-icons/hi';
import { IoPlayCircleOutline } from 'react-icons/io5';
import ReactPlayer from 'react-player';
import SquareOneProfile from '../assets/SquareOne-Profile.pdf';
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const AboutHome = () => {
    const control = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });
     const { t } = useTranslation();
     
    useEffect(() => {
        console.log(inView);

        if (inView) {
            control.start("visible");
        } else {
            control.start('hidden')
        }
    }, [control, inView]);

    const boxVariant = {
        visible: { y: -20, transition: { duration: 2 } },
        hidden: { y: 0, transition: { duation: 2 } },
    }

    return (
        <div className='py-16 px-4 sm:px-8 md:px-10 lg:px-24'>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-5 justify-items-end">
                <motion.div
                    ref={ref}
                    variants={boxVariant}
                    animate={control}
                    initial="hidden"
                    className='col-span-3'>
                    <span className="relative uppercase font-semibold text-xl text-primary">{t('title')}</span>
                    <p className='leading-7 mt-4 text-slate-700'>{t('description')}</p>
                    <div className="flex gap-5">
                        <a download="SquareOne-Profile" href={SquareOneProfile} className='rounded-full py-2 px-4 bg-primary shadow-lg hover:shadow-2xl cursor-pointer hover:bg-primary-50 w-fit text-white transition-all duration-500 ease-in-out mt-4'>
                            <div className="flex gap-2 items-center">
                                <HiOutlineDownload /> <div>{t('download_profile')}</div>
                            </div>
                        </a>
                    </div>
                </motion.div>
                <div className='col-span-2 w-full'>
                    <ReactPlayer
                        url="https://www.youtube.com/watch?v=ahL8fLcIYcY"
                        controls={false}
                        width="100%"
                        height="100%"
                        style={{ minHeight: '250px' }}
                        light
                        playing={true}
                        playIcon={
                            <div className='flex flex-col items-center'>
                                <span className="relative flex bg-white rounded-full w-fit justify-center">
                                    <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                                    <IoPlayCircleOutline className='relative inline-flex rounded-full' color='white' fill='#4CAf50' size={50} />
                                </span>
                                <div className='text-white font-normal'>{t('click_to_watch')}</div>
                            </div>
                        }
                    />

                </div>
            </div>
        </div>
    )
}

export default AboutHome