import React from 'react'
import Banner from '../components/Banner'
import AboutHome from '../components/AboutHome'
import ProductsHome from '../components/ProductsHome'
import ProjectsHome from '../components/ProjectsHome'
import PartnersHome from '../components/PartnersHome'
import Feedback from '../components/Feedback'
import BlogsSnippet from '../components/BlogsSnippet'
import WhatsApp from '../components/WhatsApp'

const Home = () => {
    return (
        <div>
            <Banner />
            <AboutHome />
            <ProductsHome />
            <ProjectsHome />
            <PartnersHome />
            {/* <Feedback /> */}
            <BlogsSnippet />
            <WhatsApp />
        </div>
    )
}

export default Home