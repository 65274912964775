import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import WhatsApp from '../components/WhatsApp';
import heroImage from '../assets/products-bg.jpg';
import { FiDownload } from 'react-icons/fi';
import { useLanguageContext } from '../services/language.context';
import { fetchBlogs } from '../services/SQAPI';
import { useTranslation } from 'react-i18next';

const BlogDetails = () => {
    let location = useLocation();
    const [value, setValue] = useState<any>();
    const { currentLanguage } = useLanguageContext();
    const { t } = useTranslation();

    useEffect(() => {
        getBlogs();
    }, [location.pathname]);

    const getBlogs = async () => {
        const data = await fetchBlogs();
        console.log("🚀 ~ getBlogs ~ data:", data)
        const title = location.pathname.split('/')[2].replaceAll('_', ' ');
        const filteredVal = data.filter((val: any) => val.blogTitle.en === title);
        setValue(filteredVal[0]);
    }


    return (
        <div>
            <div className='relative h-52'>
                <img src={heroImage} className='absolute top-0 left-0 h-52 object-cover w-full' alt='' />
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
                <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
                    <div className='font-bold text-3xl'>{t('blog')}</div>
                </div>
            </div>
            <div className='py-6 px-4 sm:px-8 md:px-16 lg:px-36'>
                <div className="flex justify-center">
                    <div>

                        <div className='text-2xl font-semibold mb-4'>{value?.blogTitle?.[currentLanguage]}</div>
                        <a href={value?.pdf[0]} target='_blank' rel='noreferrer' className='flex items-center gap-2 rounded bg-primary py-2 px-4 w-fit text-white mb-4'>
                            <FiDownload />
                            <div>Download</div>
                        </a>
                        <div className='flex justify-center'>

                        <img src={value?.images[0]} alt="" className='h-fit mb-8'  />
                        </div>
                        <div className='mt-8' >
                            <div className='leading-8 text-base' dangerouslySetInnerHTML={{ __html: value?.content[currentLanguage] }} />
                        </div>
                    </div>
                </div>
            </div>
            <WhatsApp />
        </div>
    )
}

export default BlogDetails