import { useEffect, useState } from 'react'
import { IoLocationSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { fetchProjects } from '../services/SQAPI'
import { useLanguageContext } from '../services/language.context'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const ProjectsList = () => {
    const [projects, setProjects] = useState<any>([]);
    const { currentLanguage } = useLanguageContext();
    const control = useAnimation();

    useEffect(() => {
        control.start('visible');
        
    }, []);
    useEffect(() => {
        getProjects();
    }, [])

    const getProjects = async () => {
        const data = await fetchProjects();
        console.log("🚀 ~ file: ProjectsList.tsx:16 ~ getProducts ~ data:", data)
        setProjects(data);
    }

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {projects.map((val: any, i: number) => (
                <motion.div
                    variants={{
                        visible: { y: -20, opacity: 1, transition: { duration: 2, delay: i / 5 } },
                        hidden: { y: 0, opacity: 0, transition: { duation: 2 } },
                    }}
                    animate={'visible'}
                    initial="hidden"
                    className='cursor-pointer overflow-hidden relative h-72 group shadow-md'
                >
                    <Link to={`/projects/details/${val?.projectName?.en?.replaceAll(' ', '-')}`} >
                        <img src={val.images[0]} className='w-full object-cover h-full' alt={val.projectName.en} />
                        <div className='absolute bottom-0 left-0 rounded-sm py-2 px-3 w-full'>
                            <div className='bg-slate-100 opacity-70 absolute top-0 left-0 h-full w-full group-hover:bg-primary group-hover:opacity-100 transition-all duration-400 ease-in-out'></div>
                            <div className='relative font-medium text-base text-black group-hover:text-white transition-all duration-400 ease-in-out'>{val.projectName[currentLanguage]}</div>
                            <div className='relative text-sm text-slate-700 group-hover:text-white transition-all duration-400 ease-in-out'>{val.client[currentLanguage]}</div>
                            <div className='relative flex gap-1 items-center group-hover:text-white transition-all duration-400 ease-in-out'><IoLocationSharp /> {val.location[currentLanguage]}</div>
                        </div>
                    </Link>
                </motion.div>
            ))}
        </div>
    )
}

export default ProjectsList