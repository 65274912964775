import React, { createContext, useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../assets/translation/en.json';
import translationAR from '../assets/translation/ar.json';

const resources = {
    en: {
        translation: translationEN,
    },
    ar: {
        translation: translationAR,
    },
};

const LanguageContext = createContext<any>(null);
export const LanguageContextProvider = ({ children }: any) => {
    const [currentLanguage, setCurrentLanguage] = useState('en'); // Default language is English

    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
        document.body.dir = currentLanguage === 'en' ? 'ltr' : 'rtl';
    }, [currentLanguage]);

    const toggleLanguage = () => {
        console.log("🚀 ~ file: LanguageContext.tsx:16 ~ toggleLanguage ~ currentLanguage:", currentLanguage);
        setCurrentLanguage(currentLanguage === 'en' ? 'ar' : 'en');
    };
    return <LanguageContext.Provider value={{ currentLanguage, toggleLanguage }}>{children}</LanguageContext.Provider>

};

export const useLanguageContext = () => useContext(LanguageContext);
