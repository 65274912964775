import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const FloatingWhatsappIcon = () => {
    const handleWhatsappClick = () => {
        const phoneNumber = '+966562222678';
        const message = 'Hello!';

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className="fixed bottom-4 right-4 bg-green-500 hover:bg-green-600 rounded-full p-3 cursor-pointer" onClick={handleWhatsappClick}>
            <FaWhatsapp className="text-white text-xl" size={30} />
        </div>
    );
};

export default FloatingWhatsappIcon;
