import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Carousel } from 'react-responsive-carousel';
import project1 from '../assets/caraousel-1.jpg';
import project2 from '../assets/caraousel-2.jpg';
import project3 from '../assets/caraousel-3.jpg';
import project4 from '../assets/caraousel-4.jpg';
import project5 from '../assets/caraousel-5.jpg';
//@ts-ignore
// import Slider from "react-animated-slider";
// import "react-animated-slider/build/horizontal.css";
// import "normalize.css/normalize.css";
import { animated, useSpring } from '@react-spring/web';
import "../styles/custom.scss";
import { useLanguageContext } from '../services/language.context';

const content = [
    {
        title: {
            en: "SquareOne General Contracting Company",
            ar: "شركة اسكوير ون للمقاولات"
        },
        description: {
            en: "Experience the SquareOne difference where Sustainability, and Innovation meets Excellence",
            ar: "تعرف على الفرق في اسكوير ون ، حيث الاستدامة و الابتكار تلتقي بالتوفق"
        },

        image: project1,
    },
    {
        title: {
            en: "ISOTRACK",
            ar: "ايزوتراك",
        },
        description: {
            en: "Ground Protection Mats for Temporary Access Roads",
            ar: "فرشات الحماية الارضية للطرق المؤقتة"
        },
        image: project2,
    },
    // {
    //     title: {
    //         en: "ContainersME",
    //         ar: "ME كونتينرس "
    //     },
    //     description: {
    //         en: "Fast Construction, High Quality, Low Maintenance & Transportable",
    //         ar: "انشاءات سريعة ، جودة عالية ، صيانة منخفضة و قابلية الترحيل"
    //     },
    //     image: project3,
    // },
    {
        title: {
            en: "WOODPAD",
            ar: "وأدباد"
        },
        description: {
            en: "Covered Hall",
            ar: "قاعة وود باد المغطاة"
        },
        image: project4,
    },
    {
        title: {
            en: "MobiSport",
            ar: "موبي سبورت"
        },
        description: {
            en: "Sports Bench, Ice Box & Sign Board",
            ar: "دكة رياضية ،، صندوق ثلج و لوحة اعلانات"
        },
        image: project5,
    }
];

// const boxVariant = {
//     hidden: {
//         y: "200%",
//         color: "#0055FF",
//         transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 }
//     },
//     visible: {
//         y: 0,
//         color: "#0055FF",
//         transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 }
//     }
// };

// const characterAnimation = {
//     hidden: {
//         opacity: 0,
//         y: `0.25em`,
//     },
//     visible: {
//         opacity: 1,
//         y: `0em`,
//         transition: {
//             duration: 1,
//             ease: [0.2, 0.65, 0.3, 0.9],
//         },
//     },
// };

// const container = {
//     visible: {
//         transition: {
//             staggerChildren: 0.025
//         }
//     }
// };

const CarouselSlide = ({ item, isActive }: any) => {
    const [ref, inView] = useInView();
    const control = useAnimation();
    const { currentLanguage } = useLanguageContext();

    // useEffect(() => {
    //     console.log('Effect', inView);
    //     if (inView) {
    //         console.log('Visible');

    //         control.start("visible");
    //     } else {
    //         control.start('hidden')
    //     }
    // }, [control, inView]);

    const props = useSpring({
        transform: isActive ? 'scale(1.2)' : 'scale(1)', // Scale up if the slide is active, otherwise scale to normal size
        config: { duration: 3000 }, // Animation duration in milliseconds
    });

    return (
        <div className=''>
            <animated.div className={`relative h-[320px] md:h-screen ${isActive ? 'z-10' : 'z-0'}`} style={props}>
                <img src={item.image} alt="" className='h-full w-full object-fill top-0 left-0 absolute max-w-full' />
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
            </animated.div>
            <div className='h-full w-full top-0 left-0 absolute z-30' id="textcontent">
                <div className='slider-content'>
                    <div className="animate-upwardFolding">
                        <div className='text-2xl md:text-6xl font-black font-merriweather text-white '>{item?.title[currentLanguage]}</div>
                        <div className='md:w-2/3 inline-block mt-4 overflow-hidden font-prata md:text-xl text-white'>{item?.description[currentLanguage]}</div>
                    </div>


                    {/*
                    <motion.div
                        className="pt-10"
                        initial="hidden"
                        animate={control}
                        variants={container}
                    >
                        <div>
                            {
                                item?.title.split("").map((character: string, index: number) => (
                                    <motion.span
                                        ref={ref}
                                        aria-hidden="true"
                                        key={index}
                                        variants={characterAnimation}
                                        className='text-3xl md:text-6xl font-black font-merriweather text-white'
                                    >
                                        {character}
                                    </motion.span>
                                ))
                            }
                        </div> 
                        <div className='w-2/3 inline-block mt-4' style={{ lineHeight: 0 }}>
                            {
                                item?.description.split(" ").map((element: any, i: any) => {
                                    return (
                                        <span className='overflow-hidden inline-block' key={i}>
                                            <motion.span className='inline-block' variants={boxVariant}>
                                                <div className="font-prata  text-white">
                                                    <div className='text-lg md:text-xl'>{element}&nbsp;</div>
                                                </div>
                                                <br />
                                            </motion.span>
                                        </span>
                                    )
                                })
                            }
                        </div>

                    </motion.div>*/}
                </div>
            </div>
        </div>
    );
};


const Banner = () => {
    const control = useAnimation();
    const [activeSlide, setActiveSlide] = useState(0);
    const [ref, inView] = useInView();

    useEffect(() => {
        console.log('Effect', inView);

        if (inView) {
            console.log('Visible');

            control.start("visible");
        } else {
            control.start('hidden')
        }
    }, [control, inView]);


    const reloadAnim = (index: number) => {
        setActiveSlide(index);
        // console.log('Triigeered');
        control.start("hidden");
        setTimeout(() => {
            control.start("visible");
        }, 1000);
    }

    return (
        <div >
            <div ref={ref} style={{ direction: 'initial' }}>
                <Carousel
                    autoPlay
                    animationHandler="slide"
                    interval={4000}
                    onChange={reloadAnim}
                    showArrows={false}
                    infiniteLoop
                    showThumbs={false}
                    stopOnHover={false}
                    showIndicators
                    showStatus={false}
                    selectedItem={activeSlide}
                    swipeable={false}

                >
                    {content.map((item, index) => (
                        <CarouselSlide key={index} item={item} isActive={index === activeSlide} />
                    ))}
                </Carousel>
            </div>
        </div>
    )
}

export default Banner