import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { IoLocationSharp } from 'react-icons/io5';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { fetchProjects } from '../services/SQAPI';
import { useLanguageContext } from '../services/language.context';
import { useTranslation } from 'react-i18next';

const ProjectsHome = () => {
    const [projects, setProjects] = useState<any>([]);
    const control = useAnimation();
    const { t } = useTranslation();

    const { currentLanguage } = useLanguageContext();
    useEffect(() => {
        getProducts();
    }, [])

    const getProducts = async () => {
        const data = await fetchProjects();
        setProjects(data);
    }

    const [ref, inView] = useInView({ triggerOnce: true });
    useEffect(() => {
        console.log(inView);
        control.start('hidden')
        if (inView) {
            control.start("visible");
        } else {
            control.start('hidden')
        }
    }, [control, inView]);

    const boxVariant = {
        visible: { y: -20, opacity: 1, transition: { duration: 1 } },
        hidden: { y: 0, opacity: 0, transition: { duration: 1 } },
    }


    return (
        <div className='py-16 px-4 sm:px-8 md:px-10 lg:px-24'>
            <motion.div
                ref={ref}
                variants={boxVariant}
                animate={control}
                initial="hidden"
            >
                <div className="flex justify-center">
                    <span className="relative uppercase font-semibold text-xl text-primary">{t('home_project_title')}</span>
                </div>
                <p className='text-center py-4 leading-7 mt-4 text-slate-700'>{t('home_project_subtitle')}</p>
            </motion.div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
                {projects.map((val: any, i: number) => (
                    i < 4 &&
                    <Link to={`/projects/details/${val.projectName?.en?.replaceAll(' ', '-')}`}>
                        <motion.div
                            ref={ref}
                            variants={{
                                visible: { y: -20, opacity: 1, transition: { duration: 2, delay: i / 5 } },
                                hidden: { y: 0, opacity: 0, transition: { duation: 2 } },
                            }}
                            animate={control}
                            initial="hidden"
                            className='cursor-pointer rounded-md overflow-hidden relative h-72 group shadow-md'
                        >
                            <img src={val.images[0]} className='w-full object-cover h-full' alt={val.projectName.en} />
                            <div className='absolute bottom-0 left-0 rounded-sm py-2 px-3 w-full'>
                                <div className='bg-slate-100 opacity-70 absolute top-0 left-0 h-full w-full group-hover:bg-primary group-hover:opacity-100 transition-all duration-400 ease-in-out'></div>
                                <div className='relative font-medium text-base text-black group-hover:text-white transition-all duration-400 ease-in-out'>{val.projectName[currentLanguage]}</div>
                                <div className='relative text-sm text-slate-700 group-hover:text-white transition-all duration-400 ease-in-out'>{val.client[currentLanguage]}</div>
                                <div className='relative flex gap-1 items-center group-hover:text-white transition-all duration-400 ease-in-out'><IoLocationSharp /> {val.location[currentLanguage]}</div>
                            </div>
                        </motion.div>
                    </Link>
                ))}
            </div>
            <Link to='/projects'>
                <motion.div
                    ref={ref}
                    variants={boxVariant}
                    animate={control}
                    initial="hidden"
                    className="flex justify-center mt-8"
                >
                    <div className='rounded-full py-1 px-4 bg-primary shadow-lg hover:shadow-2xl cursor-pointer hover:bg-primary-50 w-fit font-semibold text-white transition-all duration-500 ease-in-out'>{t('see_more')}</div>
                </motion.div>
            </Link>
        </div>
    )
}

export default ProjectsHome