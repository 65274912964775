import { AiOutlinePhone } from 'react-icons/ai';
import { HiOutlineLocationMarker, HiOutlineMail } from 'react-icons/hi';
import heroImage from '../assets/products-bg.jpg';
import WhatsApp from '../components/WhatsApp';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    const form = useRef<HTMLFormElement | null>(null);
    const [mailSuccess, setMailSuccess] = useState(false);

    const sendEmail = (e: any) => {
        setMailSuccess(false);
        e.preventDefault();
        if (form.current) {
            emailjs.sendForm('service_2atih02', 'template_n8swsko', form.current, 'F9FU6lo-gBO2azB7i')
                .then((result) => {
                    console.log(result.text);
                    setMailSuccess(true);
                }, (error) => {
                    console.log(error.text);
                });
        }
        form.current?.reset();
        setTimeout(() => {
            setMailSuccess(false);
        }, 8000);
    };


    return (
        <div>
            <div className='relative h-52'>
                <img src={heroImage} className='absolute top-0 left-0 h-52 object-cover w-full' alt='' />
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
                <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
                    <div className='font-bold text-3xl'>{t('contact_us')}</div>
                </div>
            </div>
            <div className='py-6 px-4 sm:px-8 md:px-10 lg:px-24'>
                <iframe
                    title='GoogleMap'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3574.408492521542!2d50.103853799999996!3d26.377987299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e50081ae40e7%3A0xf06c3c977466a49d!2zU3F1YXJlIE9uZSBHZW5lcmFsIENvbnRyYWN0aW5nIENvbXBhbnkg2LTYsdmD2Kkg2KfZhNmF2LHYqNi5INin2YTYo9mI2YQg2YTZhNmF2YLYp9mI2YTYp9iqINin2YTYudin2YXYqQ!5e0!3m2!1sen!2ssa!4v1685478217640!5m2!1sen!2ssa"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 justify-between mt-6">
                    <div>
                        <div className='text-3xl py-2'>{t('contact_title')}</div>
                        <div className="flex flex-col gap-5">
                            <a href="tel:+966562222678" className="flex gap-5">
                                <div className='bg-[#e8fbca] rounded-lg text-primary p-2'><AiOutlinePhone size={20} /></div>
                                <p className="text-body-color text-base">{t('phone_number')}</p>
                            </a>
                            <a href="mailto:info@sq1.com" className="flex gap-5">
                                <div className='bg-[#e8fbca] rounded-lg text-primary p-2'><HiOutlineMail size={20} /></div>
                                <p className="text-body-color text-base">info@sq1.sa</p>
                            </a>
                            <a href='https://goo.gl/maps/HS2GytZtCNV2QQxH8'
                                target='_blank'
                                rel="noreferrer" className="flex gap-5">
                                <div className='bg-[#e8fbca] rounded-lg text-primary p-2 h-fit'><HiOutlineLocationMarker size={20} /></div>
                                <p className="text-body-color text-base">{t('address_line1')}<br />
                                    {t('address_line2')}<br />
                                    {t('address_line3')}</p>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="relative rounded-lg bg-white p-8 shadow-lg sm:p-12">
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="mb-3">
                                    <input
                                        required
                                        type="text"
                                        name='name'
                                        placeholder={t('name')}
                                        className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-1 px-[14px] text-base outline-none focus-visible:shadow-none"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        required
                                        type="email"
                                        name='email'
                                        placeholder={t('email')}
                                        className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-1 px-[14px] text-base outline-none focus-visible:shadow-none"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        required
                                        type="text"
                                        name='phone'
                                        placeholder={t('phone')}
                                        className="text-body-color border-[f0f0f0] focus:border-primary w-full rounded border py-1 px-[14px] text-base outline-none focus-visible:shadow-none"
                                    />
                                </div>
                                <div className="mb-3">
                                    <textarea
                                        required
                                        name='message'
                                        rows={3}
                                        placeholder={t('message')}
                                        className="text-body-color border-[f0f0f0] focus:border-primary w-full resize-none rounded border py-1 px-[14px] text-base outline-none focus-visible:shadow-none"
                                    ></textarea>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="bg-primary border-primary w-full rounded border p-3 text-white transition hover:bg-opacity-90"
                                    >
                                        {t('submit')}
                                    </button>
                                </div>
                            </form>
                            {
                                mailSuccess &&
                                <div className='p-2 text-center rounded-md stroke-green-700 bg-green-100 my-4'>{t('msg_feedback')}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <WhatsApp />
        </div>
    )
}

export default Contact
