import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ProductItem = ({ children }: { children: React.ReactNode }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
    };

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={variants}
            transition={{ duration: 2 }}            
        >
            {children}
        </motion.div>
    );
};

export default ProductItem;