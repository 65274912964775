import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import ProductList from './ProductList';
import { useTranslation } from 'react-i18next';

const ProductsHome = () => {
    const control = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });
    const { t } = useTranslation();

    useEffect(() => {
        console.log(inView);

        if (inView) {
            control.start("visible");
        } else {
            control.start('hidden')
        }
    }, [control, inView]);

    const boxVariant = {
        visible: { y: -20, opacity: 1, transition: { duration: 1 } },
        hidden: { y: 0, opacity: 0, transition: { duation: 1 } },
    }

    return (
        <div className="bg-slate-100">
            <div className='py-16 px-4 sm:px-8 md:px-10 lg:px-24'>
                <motion.div
                    ref={ref}
                    variants={boxVariant}
                    animate={control}
                    initial="hidden"
                >
                    <div className="flex justify-center">
                        <span className="relative text-center uppercase font-semibold text-xl text-primary">{t('home_product_title')}</span>
                    </div>
                    <p className='text-center py-4 leading-7 mt-4 text-slate-700'>{t('home_product_subtitle')}</p>
                </motion.div>
                <ProductList />

            </div>
        </div>
    )
}

export default ProductsHome