import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { Carousel } from 'react-responsive-carousel';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import heroImage from '../assets/products-bg.jpg';
import ProductItem from '../components/ProductItem';
import WhatsApp from '../components/WhatsApp';
import { fetchProducts } from '../services/SQAPI';
import { useLanguageContext } from '../services/language.context';

const Products = () => {
    const [products, setProducts] = useState<any>([]);
    const { currentLanguage } = useLanguageContext();
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState<any>(0);

    useEffect(() => {
        getProducts();
    }, [])

    const getProducts = async () => {
        const data = await fetchProducts();
        console.log("🚀 ~ getProducts ~ data:", data)
        setProducts(data);
    }


    return (
        <div>
            <div className='relative h-52'>
                <img src={heroImage} className='absolute top-0 left-0 h-52 object-cover w-full' alt='' />
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
                <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
                    <div className='font-bold text-3xl'>{t('products')}</div>
                </div>
            </div>
            <div className='py-6 px-4 sm:px-8 md:px-10 lg:px-24'>
                <div className='leading-7 my-6 text-slate-700'>{t('product_desc')}</div>
                <div>
                    {products.map((val: any, index: number) => (
                        <ProductItem key={index}>
                            <div
                                className='grid grid-cols-4 border-b last:border-0 border-gray-300 py-6 gap-5 mb-10 justify-center'>
                                <div className='col-span-4 md:col-span-3'>
                                    <img src={val.thumbnail[0]} alt="" width={200} className='mb-2' />
                                    <div className='uppercase font-semibold mb-2'>{val.productName[currentLanguage]}</div>
                                    <div className='leading-7 text-gray-600'>{val.description[currentLanguage]}</div>
                                </div>
                                <div className='col-span-4 md:col-span-1'>
                                    <Gallery>
                                        <Item original={val.images[selectedImage]}
                                            width="1024"
                                            height="768">
                                            {({ ref, open }) => (
                                                <div style={{ direction: 'initial' }}>
                                                    <Carousel showThumbs={false} onChange={(index) => setSelectedImage(index)}>
                                                        {val?.images.map((image: any, imageIndex: number) => (
                                                            <div key={imageIndex} onClick={(e) => { setSelectedImage(imageIndex); open(e); }}>
                                                                <img src={image} alt={`Slide ${imageIndex}`} className='h-80 md:h-48 w-48 object-fill' />
                                                            </div>
                                                        ))}
                                                    </Carousel>
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                </div>
                            </div>
                        </ProductItem>
                    ))}
                </div>
            </div>
            <WhatsApp />
        </div>
    )
}

export default Products