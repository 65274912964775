import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchBlogs } from '../services/SQAPI';
import { useLanguageContext } from '../services/language.context';

const BlogsList = () => {
    const [blogsArray, setBlogsArray] = useState<any>([]);
    const { currentLanguage } = useLanguageContext();

    useEffect(() => {
        const getBlogs = async () => {
            const data = await fetchBlogs();
            console.log("🚀 ~ getBlogs ~ data:", data)
            setBlogsArray(data);
        }
        getBlogs();
    }, []);



    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {blogsArray.map((val: any) => (
                <Link to={`/blogs/${val.blogTitle.en?.replaceAll(' ', '_')}`} className='rounded-lg bg-white p-2 shadow-lg hover:ring hover:z-10 hover:ring-primary transition-all duration-400 ease-in-out' >
                    <div className='cursor-pointer overflow-hidden relative h-44 rounded-md'>
                        <img src={val.images[0]} className='w-full object-cover h-full' alt={val.blogTitle[currentLanguage]} />
                    </div>
                    <div className='mt-2'>
                        <div className='font-medium'>{val.blogTitle[currentLanguage]}</div>
                        {/* <div className='text-sm text-slate-500'>{val.publishDate}</div> */}
                    </div>
                </Link>
            ))}
        </div>
    )
}

export default BlogsList