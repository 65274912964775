import 'photoswipe/dist/photoswipe.css';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { useLocation } from 'react-router-dom';
import heroImage from '../assets/products-bg.jpg';
import WhatsApp from '../components/WhatsApp';
import { fetchProjects } from '../services/SQAPI';
import { useLanguageContext } from '../services/language.context';
import { useTranslation } from 'react-i18next';

const smallItemStyles: CSSProperties = {
    cursor: 'pointer',
    objectFit: 'cover',

}

const ProjectDetails = () => {
    let location = useLocation();
    const [value, setValue] = useState<any>();
    const { currentLanguage } = useLanguageContext();
    const { t } = useTranslation();
    useEffect(() => {
        getProjects();
    }, [location.pathname]);

    const getProjects = async () => {
        const data = await fetchProjects();
        const title = location.pathname.split('/')[3].replaceAll('-', ' ');
        const filteredVal = data.filter((val: any) => val?.projectName?.en === title);
        setValue(filteredVal[0]);
    }

    return (
        <div>
            <div className='relative h-52'>
                <img src={heroImage} className='absolute top-0 left-0 h-52 object-cover w-full' alt='' />
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
                <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
                    <div className='font-bold text-3xl uppercase'></div>
                </div>
            </div>
            <div className='py-6 px-4 sm:px-8 md:px-10 lg:px-24'>
                <div className="text-3xl font-bold mb-4">{value?.projectName[currentLanguage]}</div>
                <div className=' text-gray-600 leading-7 mb-6'>{value?.description[currentLanguage]}</div>
                <div className="grid grid-cols-3 justify-between gap-8">
                </div>
                <div className="text-lg py-4 font-semibold">{t('gallery')}</div>
                <Gallery>
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5'>
                        {value?.images.map((val: any) => (
                            <Item
                                cropped
                                original={val}
                                thumbnail={val}
                                width="1920"
                                height="2879"
                            >
                                {({ ref, open }) => (
                                    <img
                                        className='w-full object-cover h-60'
                                        src={val}
                                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                                        onClick={open}
                                        alt=""
                                    />
                                )}
                            </Item>
                        ))}

                    </div>
                </Gallery>
                {/* <Carousel className='mt-6' dynamicHeight showArrows autoPlay infiniteLoop showThumbs stopOnHover showIndicators showStatus={false}>
                    {value?.images.map((val: any) => (
                        <div>
                            <img src={val} alt="" className='w-full object-cover h-full' />
                        </div>
                    ))}
                </Carousel> */}
            </div >
            <WhatsApp />
        </div >
    )
}

export default ProjectDetails