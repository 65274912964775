import React from 'react'
import BlogsList from './BlogsList'
import { Link } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const BlogsSnippet = () => {
    const control = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });
    const { t } = useTranslation();

    useEffect(() => {
        console.log(inView);

        if (inView) {
            control.start("visible");
        } else {
            control.start('hidden')
        }
    }, [control, inView]);

    const boxVariant = {
        visible: { y: -30, opacity: 1, transition: { duration: 1 } },
        hidden: { y: 0, opacity: 0, transition: { duation: 1 } },
    }
    return (
        <motion.div
            ref={ref}
            variants={boxVariant}
            animate={control}
            initial="hidden"
            className='py-16 px-4 sm:px-8 md:px-10 lg:px-24'>
            <div className="flex justify-center mb-8">
                <span className="relative uppercase font-semibold text-xl text-primary">{t('home_blog_title')}</span>
            </div>
            <BlogsList />
            <Link to='/blogs' className="flex justify-center mt-4">
                <div className='py-1 px-4 rounded-full bg-primary shadow-lg hover:shadow-2xl cursor-pointer hover:bg-primary-50 w-fit font-semibold text-white transition-all duration-500 ease-in-out'>{t('see_more')}</div>
            </Link>
        </motion.div>
    )
}

export default BlogsSnippet