import React, { useEffect } from 'react'
import heroImage from '../assets/products-bg.jpg';
import logo from '../assets/Logo.png';
import WhatsApp from '../components/WhatsApp';
import { Values } from '../constants/data';
import { useLanguageContext } from '../services/language.context';
import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutUs = () => {
    const { currentLanguage } = useLanguageContext();
    const { t } = useTranslation();
    const controlContent1 = useAnimation();
    const controlContent2 = useAnimation();
    const [content1, inViewContent1] = useInView({ triggerOnce: true });
    const [content2, inViewContent2] = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inViewContent1) {
            controlContent1.start("visible");
        } else {
            controlContent1.start('hidden')
        }
    }, [controlContent1, inViewContent1]);

    useEffect(() => {
        if (inViewContent2) {
            controlContent2.start("visible");
        } else {
            controlContent2.start('hidden')
        }
    }, [controlContent2, inViewContent2]);


    const boxVariant = {
        visible: { y: 0, transition: { duration: 2 } },
        hidden: { y: 20, transition: { duation: 2 } },
    }
    return (
        <div>
            <div className='relative h-52'>
                <img src={heroImage} className='absolute top-0 left-0 h-52 object-cover w-full' alt='' />
                <div className="bg-black h-full w-full top-0 left-0 absolute opacity-50"></div>
                <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
                    <div className='font-bold text-3xl'>{t('about_us')}</div>
                </div>
            </div>

            <div className='py-6 px-4 sm:px-8 md:px-10 lg:px-24'>
                <motion.div
                    ref={content1}
                    variants={boxVariant}
                    animate={controlContent2}
                    initial="hidden" >
                    <div className='text-gray-600 uppercase mt-2 text-center font-semibold'>{t('mission_values')}</div>
                    <div className='text-3xl text-center text-primary mb-2'>{t('title')}</div>
                    <div className="text-center leading-7">{t('company_description')}</div>
                </motion.div>

                <motion.div
                    ref={content2}
                    variants={boxVariant}
                    animate={controlContent2}
                    initial="hidden" className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-5 text-center">
                    <div>
                        <div className="flex items-center py-4">
                            <div className="flex-grow h-px bg-gray-400"></div>
                            <span className="flex-shrink text-2xl text-gray-500 px-4 italic font-light">{t('our_vision')}</span>
                            <div className="flex-grow h-px bg-gray-400"></div>
                        </div>
                        <div className="leading-7">{t('vision_desc')}</div>
                    </div>
                    <div>
                        <div className="flex items-center py-4">
                            <div className="flex-grow h-px bg-gray-400"></div>
                            <span className="flex-shrink text-2xl text-gray-500 px-4 italic font-light">{t('our_mission')}</span>
                            <div className="flex-grow h-px bg-gray-400"></div>
                        </div>
                        <div className="leading-7">{t('mission_desc')}</div>
                    </div>
                </motion.div>
                <div className='mt-8'>
                    <div className="flex items-center py-4">
                        <div className="flex-grow h-px bg-gray-400"></div>
                        <span className="flex-shrink text-2xl text-gray-500 px-4 italic font-light">{t('our_values')}</span>
                        <div className="flex-grow h-px bg-gray-400"></div>
                    </div>
                    <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-3'>
                        {Values.map((val: any, i: number) => (
                            <motion.div
                                ref={content1}
                                variants={{
                                    visible: { y: -20, opacity: 1, transition: { duration: 2, delay: i / 5 } },
                                    hidden: { y: 0, opacity: 0, transition: { duation: 2 } },
                                }}
                                animate={controlContent1} className='rounded p-3 border border-gray-200 bg-slate-100'>
                                <div className="flex justify-center mb-2">
                                    <img src={val.icon} alt="" width={130} />
                                </div>
                                <div className='text-center text-primary'>{val.title[currentLanguage]}</div>
                                <div className='text-center'>{val.desc[currentLanguage]}</div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
            <WhatsApp />
        </div>
    )
}

export default AboutUs
