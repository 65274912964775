import { useState } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { fetchProducts } from '../services/SQAPI';
import { useLanguageContext } from '../services/language.context';

const ProductList = () => {
    const { currentLanguage } = useLanguageContext();
    const control = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });
    const [products, setProducts] = useState<any>([]);

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start('hidden')
        }
    }, [control, inView]);

    useEffect(() => {
        getProducts();
    }, [])

    const getProducts = async () => {
        const data = await fetchProducts();
        console.log("🚀 ~ getProducts ~ data:", data)
        setProducts(data);
    }

    const boxVariant = {
        visible: { y: 0, opacity: 1, transition: { duration: 1, delay: 0.25 } },
        hidden: { y: 30, opacity: 0 },
    }

    return (
        <motion.div
            ref={ref}
            variants={boxVariant}
            animate={control}
            initial="hidden"
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 justify-center">
            {products.map((val: any) => (
                <Link to={`/products/details/${val.productName.en.replaceAll(' ', '-')}`} className='bg-white rounded-lg shadow-lg py-4 px-6 hover:ring hover:ring-primary transition-all duration-400 ease-in-out'>
                    <div className="flex justify-center py-3">
                        <img src={val.thumbnail} alt="" width={200} />
                    </div>
                    <div className='font-semibold text-lg text-center'>{val.productName[currentLanguage]}</div>
                    <div className='text-sm text-center  leading-6 text-slate-700'>{val.shortDescription[currentLanguage]}</div>
                    <div className="flex gap-5 items-center">
                        <div>
                        </div>
                    </div>
                </Link>
            ))}
        </motion.div>
    )
}

export default ProductList